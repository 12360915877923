import { useNavigate, useParams } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import { useQuery } from "react-query";
import invert from 'invert-color';

import { getTags } from "../../core/api/api";
import Button from "../../core/components/Button";

const useStyles = createUseStyles((theme) => ({
  content: {
    display: `flex`,
    flexWrap: 'wrap'
  },
  header: {
    display: 'flex',
    alignItems: 'center',

    '& h1': {
      flexGrow: 1
    }
  },
  tag: {
    position: `relative`,
    backgroundColor: '#F1F1F1',
    borderRadius: 20,
    padding: [8, 16],
    marginRight: 8,
    marginBottom: 16,
    cursor: 'pointer',
    transition: `all .2s`,

    '&:hover': {
      backgroundColor: '#E0E0E0',
    }
  }
}));

const Tags = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { project_id } = useParams();

  const {
    data: tags,
    isLoading: areTagsLoading,
    error: tagsError
  } = useQuery('tags', () => getTags({ project_id }));

  if (tagsError) {
    return 'An error has occurred: ' + tagsError.message;
  }

  return (
    <div className={classes.settings}>
      <div className={classes.header}>
        <h1>Labels</h1>
        <div className={classes.actions}>
          <Button onClick={() => navigate(`/projects/${project_id}/labels/new`)}>New Label</Button>
        </div>
      </div>
      <div className={classes.content}>
        { tags?.map((tag) => {

          let tagColor;

          try {
            tagColor = invert(tag.color, true);
          } catch {
            tagColor = 'black';
          }

          return (
            <div
              className={classes.tag}
              key={tag.id}
              onClick={() => navigate(`/projects/${project_id}/labels/${tag.id}/edit`)}
              style={{backgroundColor: tag.color, color: tagColor }}
            >{tag.name}</div>
          )}
        )}
      </div>
    </div>
  )
};

export default Tags;
