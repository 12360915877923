import { createUseStyles } from 'react-jss';
import { useQuery } from "react-query";

import { getCurrentUser, getCompany, getProjects } from "../../core/api/api";
import ButtonSmall from '../../core/components/ButtonSmall';

const useStyles = createUseStyles((theme) => ({
  settings: {
    display: 'flex',
    flexDirection: 'column',

    '& h3': {
      color: '#999',
      marginBottom: 0,
    }
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
  },

  labelEntry: {
    marginBottom: 8,
    paddingTop: 8,
    paddingBottom: 8,

    '&:last-child': {
      marginBottom: 0
    },
  },

  label: {
    fontWeight: '600',
    marginBottom: 12,
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },

  entry: {
    padding: [12, 15],
    backgroundColor: '#F0EBE6',
    borderRadius: 5,
    display: 'inline-flex',
    fontSize: 14,
    minWidth: '60%'
  },

  deleteBtn: {
    maxWidth: 160,
    fontSize: 13,
    marginTop: 30,

    appearance: 'none',
    border: 'none',
    outline: 'none',
    background: 'none',
    border: `1px dashed ${theme.color.red}`,
    color: theme.color.red,
    borderRadius: 6,
    cursor: 'pointer',
    height: 36,

    '&:hover': {
      background: theme.color.red,
      color: 'white',
    },
  },

  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',

    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },

  modalContent: {
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 20,
    width: 500,
    maxWidth: '100%',
    lineHeight: 1.5,
    position: 'relative',

    '& > span': {
      fontWeight: 'bold',
    }
  },

  closeModal: {
    position: 'absolute',
    top: 0,
    right: 22,
    cursor: 'pointer',
    fontSize: 34,
    fontWeight: 500,
    fontFamily: 'Times',
    color: '#000',
    height: 55,
    display: 'flex',
    alignItems: 'center',
  },
}));

const Settings = () => {
  const classes = useStyles();

  const { data: company, error } = useQuery('company', getCompany);
  const { data: currentUser } = useQuery('currentUser', getCurrentUser)

  const {
    data: projects,
    isLoading: areProjectsLoading,
    error: projectsError
  } = useQuery('projects', getProjects);

  const currentProject = projects?.length > 0 ? projects[0] : null;

  const deleteAccount = () => {
    const modal = document.querySelector(`.${classes.modal}`);
    modal.style.display = 'flex';
  };

  const closeModal = () => {
    const modal = document.querySelector(`.${classes.modal}`);
    modal.style.display = 'none';
  };

  if (error) {
    return 'An error has occurred: ' + error.message;
  }

  return (
    <div className={classes.settings}>
      <h1>Settings</h1>
      <div className={classes.content}>

        { currentProject && (
          <>
            {/* <h3>Project</h3> */}
            <div className={classes.labelEntry}>
              <div className={classes.label}>Project name</div>
              <div className={classes.entry}>{ currentProject?.name }</div>
            </div>
            <div className={classes.labelEntry}>
              <div className={classes.label}>API key</div>
              <div className={classes.entry}>{ currentProject?.api_key }</div>
            </div>
          </>
        ) }

        {/* <h3>Company</h3> */}
        <div className={classes.labelEntry}>
          <div className={classes.label}>Company name</div>
          <div className={classes.entry}>{ company?.name }</div>
        </div>

        {/* <h3>User</h3> */}
        <div className={classes.labelEntry}>
          <div className={classes.label}>First name</div>
          <div className={classes.entry}>{ currentUser?.first_name }</div>
        </div>
        <div className={classes.labelEntry}>
          <div className={classes.label}>Last name</div>
          <div className={classes.entry}>{ currentUser?.last_name }</div>
        </div>
        <div className={classes.labelEntry}>
          <div className={classes.label}>Email</div>
          <div className={classes.entry}>{ currentUser?.email }</div>
        </div>

        <button className={classes.deleteBtn} onClick={deleteAccount}>
          <span>Delete my account</span>
        </button>

        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <div className={classes.modalTitle}>
              <h2>Delete your account 😢</h2>
              <div onClick={closeModal} className={classes.closeModal}>&times;</div>
            </div>
            We are sorry to hear that you want to delete your account, but we understand.
            In order to fully delete your account, please contact us at <span>contact@noti.so</span>.
          </div>
        </div>
      </div>
    </div>
  )
};

export default Settings;
