import { createUseStyles } from "react-jss";

import { EditorUpdate } from '../../editor';

const useStyles = createUseStyles({
  page: {
    // padding: '30px 50px',
  },

  post: {
    background: '#fff',
    padding: '0 15px',
    border: '1px solid #f1f1f1',
    borderRadius: '5px',
    marginTop: '20px',
  }
});

const PostEdit = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      {/* <h2>Edit Post</h2> */}
      <EditorUpdate />
    </div>
  );
};

export default PostEdit;
