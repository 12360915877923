import { createUseStyles } from "react-jss";

import './react-editorjs.css';
import './react-tags.css';

import "react-datepicker/dist/react-datepicker.css";
import './react-datepicker.css';

const useStyles = createUseStyles((theme) => ({
  editorTitle: {
    '& input': {
      fontSize: 24,
      border: 'none',
      padding: [16, 60],
      width: '100%',
      fontWeight: 'bold',
    },

    '& input:focus': {
      outline: 'none !important',
    },

    '& input::placeholder': {
      color: theme.color.greyDark,
      fontWeight: 'bold',
    },
  },
  editor: {
    '& .editor-wrapper': {
      borderRadius: 3,

      // padding: 16,
      marginTop: 8,
      marginBottom: 16,
      width: '100%',
    },

    '& .editor-toolbar': {
      padding: 15,
      borderRadius: 5,
      marginTop: 16,
    },

    '& .editor-main': {
      background: '#fff',
      border: [1, 'solid', '#F1F1F1'],
      padding: [5, 15],
      borderRadius: 5,
      marginBottom: 20,
    },
  },
  settings: {
    marginTop: 24,
    marginLeft: 60,
    marginRight: 60,
    marginBottom: 24,
  },
  field: {
    background: theme.color.greyLight,
    padding: [16],
    fontSize: 14,
    marginBottom: 16,
    borderRadius: 5,
  },
  label: {
    marginBottom: 4,
    display: 'flex',
    color: theme.color.primary,

    '& label': {
      flexGrow: 1,
    }
  },
  labelOptional: {
    fontSize: 9,
    letterSpacing: 0.5,
    color: theme.color.primaryDark,
    border: [1, 'solid', theme.color.primaryDark],
    padding: 4,
    borderRadius: 3,
    marginRight: 8,
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 400,
    marginLeft: 60,

    '& button + button': {
      marginLeft: 24,
    }
  },

  twoColumns: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.lg]: {
      flexDirection: 'row',
    },
  },

  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 0,
    flexGrow: 1,

    [theme.breakpoints.lg]: {
      marginRight: 88,
    }
  },

  rightSide: {
    display: 'flex',
    marginTop: 80,

    [theme.breakpoints.lg]: {
      marginTop: 0,
      maxWidth: '50%',
    },
  },

  widgetPreview: {
    backgroundColor: 'rgba(62, 92, 235, 0.25)',
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
  },

  widget: {
    background: theme.color.white,
    color: theme.color.dark,
    maxWidth: 456,
    minWidth: 456,
    borderRadius: 8,
    overflow: 'hidden'
  },

  widgetHeader: {
    backgroundColor: theme.color.blue,
    color: theme.color.greyLight,
    fontSize: 18,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 20,
    position: 'relative',
    lineHeight: '100%'
  },

  widgetHeaderTitle: {
    flexGrow: '1',
    fontWeight: '700'
  },

  icons: {
    display: 'flex',
    position: 'absolute',
    top: '24px',
    right: '24px',

    '& div': {
      cursor: 'pointer',
      marginLeft: '16px'
    }
  },

  noPostsWrapper: {
    backgroundColor: theme.color.greyLight,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,

    '& h1': {
      fontSize: 24,
      marginBottom: 16
    },

    '& p': {
      fontSize: 18,
      lineHeight: 1.6,
      textAlign: 'center'
    },

    '& button': {
      marginTop: 24
    },
  },

  widgetBody: {
    backgroundColor: theme.color.white,
    color: 'rgba(6, 26, 36, 0.7)',
    height: 'calc(100vh - 750px)',
    minHeight: 400,
    overflow: 'hidden',
    overflowY: 'scroll',

    [theme.breakpoints.lg]: {
      height: 'calc(100vh - 208px)',
    }
  },

  widgetPost: {
    padding: [24, 20],
    position: 'relative',

    '& + &': {
      borderTop: `1px solid ${theme.color.grey}`
    }
  },

  widgetPostDate: {
    color: 'rgba(6, 26, 36, 0.5)',
    letterSpacing: '-0.005em',
    fontWeight: 700,
    fontSize: 12,
    marginBottom: 16,
    display: 'flex',

    '& > img': {
      marginRight: 6
    }
  },

  widgetPostTitle: {
    color: theme.color.dark,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '110%',
    marginBottom: 16
  },

  widgetPostImage: {
    '& > img': {
      width: '100%',
      marginBottom: 16
    }
  },

  widgetPostBody: {
    color: 'rgba(6, 26, 36, 0.7)',
    letterSpacing: '-0.005em',
    fontSize: 15,
    lineHeight: '165%',
  },

  widgetFooter: {
    backgroundColor: theme.color.dark,
    color: theme.color.white,
    fontSize: 13,
    lineHeight: '100%',
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  widgetPostTags: {
    marginBottom: 16,
  },

  postTag: {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '4px 8px',
    marginRight: '4px',
    borderRadius: '5px',
    background: '#F1F1F1',
    fontSize: '12px',
  }
}));

export default useStyles;
