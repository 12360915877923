import axios from 'axios';
import { TOKEN_NAME } from '../consts';

const urlProtocol = process.env.SERVER_PROTOCOL || 'https:';

const instance = axios.create({
  baseURL: `${urlProtocol}//${process.env.SERVER_HOST}`
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);


export default instance;
