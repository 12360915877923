import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuth from '../core/hooks/useAuth';

const RequireAuth = () => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.authToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default RequireAuth;
