import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  button: {
    appearance: 'none',
    border: 'none',
    outline: 'none',

    background: theme.color.blue,
    borderRadius: 6,
    paddingLeft: 24,
    paddingRight: 24,
    height: 40,
    cursor: 'pointer',

    '&:hover': {
      background: '#2646EB'
    },

    '&[disabled]': {
      background: theme.color.greyDark,
      cursor: 'wait'
    }
  },

  label: {
    color: theme.color.white,
    fontSize: 15,
  }
}));

const Button = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <button className={classes.button} { ...props }>
      <span className={classes.label}>{ children }</span>
    </button>
  )
};

export default Button;
