import { createUseStyles } from "react-jss";

import { Editor } from '../../editor';

const useStyles = createUseStyles({
  page: {
    // padding: '30px 50px',
  },

  post: {
    background: '#fff',
    padding: '0 15px',
    border: '1px solid #f1f1f1',
    borderRadius: '5px',
    marginTop: '20px',
  }
});

const PostNew = () => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Editor />
    </div>
  );
};

export default PostNew;
