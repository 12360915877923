import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import DatePicker from "react-datepicker";
import ReactTags from 'react-tag-autocomplete';
import invert from 'invert-color';

import Button from "../../core/components/Button";
import ErrorMessage from "../../core/components/ErrorMessage";

import { getPost, getTags, updatePost } from "../../core/api/api";

import useStyles from "./editorStyles";

import EditorJSComponent from "./EditorJSComponent";

function Tag({ tag, removeButtonText, onDelete }) {
  let tagColor;

  try {
    tagColor = invert(tag.color, true);
  } catch {
    tagColor = 'black';
  }

  return (
    <button
      type='button'
      className='react-tags__selected-tag'
      title={`${removeButtonText}: ${tag.name}`}
      onClick={onDelete}
      style={{ backgroundColor: tag.color, color: tagColor }}
    >
      {tag.name}
    </button>
  )
}

const EditorUpdate = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { project_id, post_id } = useParams();
  const queryClient = useQueryClient();

  const [title, setTitle] = useState();
  const [publishAt, setPublishAt] = useState();
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState();
  const [postBody, setPostBody] = useState();

  const [isEditorReady, setIsEditorReady] = useState(false);

  const { data: dbSuggestions } = useQuery('tags', () => getTags({ project_id }));
  const { data: post } = useQuery(['post', project_id, post_id], () => getPost({ project_id, post_id }));

  const { mutate, isLoading, error } = useMutation(updatePost, {
    onSuccess: () => {
      queryClient.invalidateQueries('posts')
      queryClient.invalidateQueries(['post', project_id, post_id])
      navigate(`/projects/${project_id}/posts`)
    }
  });

  useEffect(() => {
    if (dbSuggestions) {
      setSuggestions(dbSuggestions);
    }
  }, [dbSuggestions]);

  const reactTags = useRef();

  const onAddition = useCallback((newTag) => {
    let newTagObject = suggestions.find((suggestion) => {
      if (suggestion && newTag) {
        return suggestion?.name === newTag?.name
      }
    }) || newTag;
    setTags([...tags, newTagObject])
  }, [tags, suggestions]);

  const onDelete = useCallback((tagIndex) => {
    setTags(tags.filter((_, i) => i !== tagIndex))
  }, [tags])

  useEffect(() => {
    if (post) {
      setTitle(post.title);
      if (post.publish_at) {
        let d = new Date(post.publish_at);
        const e = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
        setPublishAt(e);
      }
      setTags(post.tags);
      setPostBody(JSON.parse(post.body));
      setIsEditorReady(true);
    }
  }, [post]);

  const handleUpdate = async () => {
    mutate({
      id: post_id,
      project_id,
      body: {
        title,
        body: JSON.stringify(postBody),
        publish_at: publishAt,
        tags,
      }
    });
  };

  if (isLoading) {
    return 'Please wait...';
  }

  return (
    <div className={classes.editor}>
      <ErrorMessage error={error} customMessagesKey='post-update' />

      <div className={classes.editorTitle}>
        <input
          autoFocus
          placeholder="Untitled Post"
          required
          type="text"
          onChange={e => setTitle(e.target.value)}
          defaultValue={title}
        />
      </div>

      <div className="editor-wrapper">
        {!isEditorReady && <div>Loading...</div>}
        {isEditorReady &&
          <EditorJSComponent
            initialData={postBody}
            onContentChange={setPostBody}
          />
        }
      </div>

      {/* <div className={classes.settings}>
        <div className={classes.field}>
          <div className={classes.label}>
            <label>Set custom date and time when you want to publish this post:</label>
            <div className={classes.labelOptional}>OPTIONAL</div>
          </div>
          <DatePicker
            selected={publishAt}
            onChange={(date) => setPublishAt(date)}
            showTimeSelect
            dateFormat="Pp"
            placeholderText="Schedule publish date and time"
          />
        </div>

        <div className={classes.field}>
          <div className={classes.label}>
            <label>Add labels:</label>
            <div className={classes.labelOptional}>OPTIONAL</div>
          </div>
          <ReactTags
            ref={reactTags}
            allowNew
            tags={tags}
            suggestions={suggestions}
            onDelete={onDelete}
            onAddition={onAddition}
            delimiters={['Enter', 'Tab', ' ']}
            placeholderText={"Labels (optional)"}
            tagComponent={Tag}
          />
        </div>
      </div> */}

      <div className={classes.buttons}>
        <Button onClick={handleUpdate}>Update</Button>
      </div>
    </div>
  );
};

export default EditorUpdate;
