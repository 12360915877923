import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { postResetUserPassword } from '../core/api/api';

import Button from '../core/components/Button';
import ErrorMessage from '../core/components/ErrorMessage'

const useStyles = createUseStyles((theme) => ({
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    background: theme.color.white
  },

  title: {
    fontFamily: theme.font.brand,
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },

  confirmation: {
    margin: 32,
    padding: 35,
    background: theme.color.greyLight,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.5,

    '& h2': {
      textAlign: 'center'
    }
  },

  text: {
    marginTop: 8,
    marginBottom: 8,
  },

  button: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center'
  },
}));

const ResetPassword = () => {
  const [ password, setPassword ] = useState();
  const { reset_password_token } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const queryClient = useQueryClient();

  const { mutate, isLoading, error, data: newUser } = useMutation(postResetUserPassword, {
    onSuccess: (data) => {
      queryClient.invalidateQueries();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    mutate({
      reset_password_token,
      new_password: password,
    })
  }

  if (newUser) {
    return (
      <div className={classes.page}>
        <div className={classes.confirmation}>
          <h2>Password changed successfully! 🚀</h2>
          <div className={classes.text}>You should be able to log in now 👇</div>
          <div className={classes.button}>
            <Button onClick={e => navigate('/login')}>Log in</Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={classes.page}>
        <div className={classes.title}>Reset your password</div>
        <ErrorMessage error={error} customMessagesKey='reset-password' />
        <form onSubmit={handleSubmit}>
          <label>
            <span>Password</span>
            <input type="password" name="password" required onChange={e => setPassword(e.target.value)}/>
          </label>
          <Button disabled={isLoading}>Reset password</Button>
        </form>
      </div>
    </>
  )
};

export default ResetPassword;
