import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMutation, useQueryClient } from 'react-query';

import Button from '../../core/components/Button';
import ErrorMessage from '../../core/components/ErrorMessage';
import { postProject } from '../../core/api/api';

const useStyles = createUseStyles((theme) => ({
  title: {
    fontFamily: theme.font.brand,
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },
}));

const ProjectForm = () => {
  const classes = useStyles();
  const [projectName, setProjectName] = useState();

  const queryClient = useQueryClient();

  const { mutate, error, isLoading } = useMutation(postProject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    mutate({ name: projectName });
  }

  return (
    <div className={classes.page}>

      <form onSubmit={handleSubmit}>
        <div className={classes.title}>Create a new project</div>
        <ErrorMessage error={error} customMessagesKey='onboarding-project' />
        <label>
          <span>Project name</span>
          <input type="text" onChange={e => setProjectName(e.target.value)}/>
        </label>
        <Button disabled={isLoading}>Create</Button>
      </form>

    </div>
  )
};

export default ProjectForm;
