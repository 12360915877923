import { useNavigate } from 'react-router-dom';
import { createUseStyles } from "react-jss";
import { useQuery } from "react-query";

import Button from '../../core/components/Button';

import { getProjects } from "../../core/api/api";

const useStyles = createUseStyles((theme) => ({
  projects: {
    display: 'flex',
    flexDirection: 'column'
  },

  project: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    // padding: '20px',
    borderRadius: '5px',
    width: '800px'
  },

  projectDetails: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  projectName: {
    fontWeight: 'bold',
  },

  projectUrl: {
    color: theme.color.greyDark,
    marginTop: 10
  },
}));

const Projects = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { data: projects, error } = useQuery('projects', getProjects);

  if (error) {
    return 'An error has occurred: ' + error.message;
  }

  return (
    <div className={classes.projects}>
      <h2>Projects</h2>
      { projects.map(project => (
        <div key={project.id} className={classes.project}>
          <div className={classes.projectDetails}>
            <div className={classes.projectName}>{ project?.name }</div>
            <div className={classes.projectUrl}>{ project?.url }</div>
          </div>
          <div className={classes.projectButtons}>
            <Button onClick={() => navigate(`/projects/${project.id}`)}>See project</Button>
          </div>
        </div>
      )) }
    </div>
  );
};

export default Projects;
