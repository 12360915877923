import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useQuery } from "react-query";

import { getProject } from "../../core/api/api";

import Button from '../../core/components/Button';

const useStyles = createUseStyles((theme) => ({
  projects: {
    display: 'flex',
    flexDirection: 'column'
  },

  project: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    // padding: '20px',
    borderRadius: '5px',
    width: '800px'
  },

  projectDetails: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  projectName: {
    fontWeight: 'bold',
  },

  projectUrl: {
    color: theme.color.greyDark,
    marginTop: 10
  },

  widget: {
    marginTop: 20,
  },

  widgetDescription: {
    padding: 20,
    backgroundColor: '#fff',
    color: theme.color.dark,
    marginBottom: 20,
    borderRadius: 10
  }
}));

const Project = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { project_id } = useParams();

  const { data: project, error } = useQuery(
    ['project', project_id],
    () => getProject({ project_id })
  );

  if (error) {
    return 'An error has occurred: ' + error.message;
  }

  return (
    <div className={classes.projects}>
      <h2>{ project?.name }</h2>
      <div className={classes.project}>
        <div className={classes.projectDetails}>
          <div className={classes.projectName}>{ project?.name }</div>
          <div className={classes.projectUrl}>{ project?.url }</div>
        </div>
        <div className={classes.projectButtons}>
          <Button onClick={() => navigate(`/projects/${project.id}/posts`)}>Posts</Button>
        </div>
      </div>
    </div>
  );
};

export default Project;
