import React, { useState } from 'react';
import { createUseStyles } from "react-jss";
import { useMutation, useQueryClient, useQuery } from 'react-query';

import WidgetSetup from "./WidgetSetup";
import WidgetCustomizationForm from "./WidgetCustomizationForm";
import WidgetPreview from "./WidgetPreview";
import WidgetPageNav from "./WidgetPageNav";

import { getProjects, updateProject } from '../../core/api/api';

const useStyles = createUseStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column'
  },

  tabs: {
    fontSize: 15,
    color: theme.color.dark,
    fontWeight: 700,
    display: 'flex',
    borderBottom: `1px solid ${theme.color.grey}`,
    marginBottom: 40,

    '& > div': {
      paddingBottom: 20,
      marginRight: 20
    }
  },

  activeTab: {
    paddingBottom: 20,
    borderBottom: `3px solid ${theme.color.dark}`
  },

  twoColumns: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.lg]: {
      flexDirection: 'row',
    },
  },

  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 0,
    flexGrow: 1,

    [theme.breakpoints.lg]: {
      marginRight: 88,
    }
  },

  rightSide: {
    display: 'flex',
    marginTop: 80,

    [theme.breakpoints.lg]: {
      marginTop: 0,
      maxWidth: '50%',
    },
  },

  widgetPreview: {
    backgroundColor: 'rgba(62, 92, 235, 0.25)',
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    top: -32,
    marginBottom: -64,
  }
}));

const WidgetPage = () => {
  const [currentTab, setCurrentTab] = useState('setup');

  const [newWidgetTitle, setNewWidgetTitle] = useState();
  const [newAccentColor, setNewAccentColor] = useState();
  const [newWidgetCornerRadius, setNewWidgetCornerRadius] = useState();

  const queryClient = useQueryClient();

  const { data: projects } = useQuery('projects', getProjects);
  const currentProject = projects?.length > 0 ? projects[0] : null;

  const { mutate, isLoading, error } = useMutation(updateProject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();

    let body = {
      widget_title: newWidgetTitle,
      widget_accent_color: newAccentColor,
      widget_corner_radius: newWidgetCornerRadius,
    }

    // Remove keys with null values
    body = Object.fromEntries(Object.entries(body).filter(([_, v]) => v != null));

    mutate({
      project_id: currentProject.id,
      body,
    });
  }

  const classes = useStyles();

  const defaultWidgetTitle = currentProject?.widget_title
  const defaultAccentColor = currentProject?.widget_accent_color
  const defaultWidgetCornerRadius = currentProject?.widget_corner_radius

  const widgetTitle = newWidgetTitle || defaultWidgetTitle
  const accentColor = newAccentColor || defaultAccentColor
  const widgetCornerRadius = newWidgetCornerRadius || defaultWidgetCornerRadius

  return (
    <div className={classes.page}>

      { currentTab == 'setup' && (
        <div>
          <WidgetPageNav currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <WidgetSetup />
        </div>
      ) }

      { currentTab == 'customization' && (
        <div className={classes.twoColumns}>
          <div className={classes.leftSide}>
            <WidgetPageNav currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <WidgetCustomizationForm
              widgetTitle={widgetTitle}
              setWidgetTitle={setNewWidgetTitle}

              accentColor={accentColor}
              setAccentColor={setNewAccentColor}

              widgetCornerRadius={widgetCornerRadius}
              setWidgetCornerRadius={setNewWidgetCornerRadius}

              handleSubmit={handleSubmit}
              isLoading={isLoading}
              error={error}
            />
          </div>
          <div className={classes.rightSide}>
            <div className={classes.widgetPreview}>
              <WidgetPreview
                widgetTitle={widgetTitle}
                accentColor={accentColor}
                widgetCornerRadius={widgetCornerRadius}
              />
            </div>
          </div>
        </div>
      ) }
    </div>
  );
};

export default WidgetPage;
