import { Outlet } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useQuery } from 'react-query';

import { getCurrentUser, getProjects } from '../core/api/api';
import useAuth from '../core/hooks/useAuth';

import ConfirmUserEmail from '../auth/ConfirmUserEmail';
import OnboardingNewCompanyForm from '../onboarding/components/OnboardingNewCompanyForm';
import OnboardingNewProjectForm from '../onboarding/components/OnboardingNewProjectForm';

import Header from '../page/components/Header';
import Sidebar from '../page/components/Sidebar';

const useStyles = createUseStyles((theme) => ({
  page: {
    display: 'flex',
  },

  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50
  },

  content: {
    width: '90%',
    alignSelf: 'center',
    margin: [32],

    [theme.breakpoints.xl]: {
      width: 976,
      margin: [32, 48],
    },
  }
}));

const PageContent = () => {
  const auth = useAuth();

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    error: currentUserError
  } = useQuery('currentUser', getCurrentUser);

  const {
    data: projects,
    isLoading: areProjectsLoading,
    error: projectsError
  } = useQuery('projects', getProjects);


  if (isCurrentUserLoading) {
    return 'Loading...';
  }

  if (currentUserError) {
    auth.logout();
    return null;
  }

  if (!currentUser.confirmed_at) {
    return <ConfirmUserEmail />
  }

  if (!currentUser.company_id) {
    return <OnboardingNewCompanyForm />
  }

  if (areProjectsLoading) {
    return 'Loading...';
  }

  if (projectsError) {
    return 'An error has occurred while loading projects: ' + projectsError.message;
  }

  if (!projects?.length) {
    return <OnboardingNewProjectForm />
  }

  return <Outlet />
}

const App = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Sidebar />
      <div className={classes.main}>
        <Header />
        <div className={classes.content}>
          <PageContent />
        </div>
      </div>
    </div>
  )
};

export default App;
