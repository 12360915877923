import { createUseStyles } from "react-jss";

import ErrorMessage from '../../core/components/ErrorMessage';
import Button from '../../core/components/Button';

const useStyles = createUseStyles((theme) => ({
  page: {
    display: 'flex',
  },
  pageColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    alignItems: 'center',

    [theme.breakpoints.lg]: {
      width: '50%',
      alignItems: 'flex-start',
    }
  }
}));

const WidgetCustomizationForm = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <div className={classes.pageColumn}>
        <ErrorMessage error={props.error} customMessagesKey='widget' />
        <form onSubmit={props.handleSubmit}>
          <label>
            <span>Widget title</span>
            <input
              type="text"
              onChange={e => props.setWidgetTitle(e.target.value)}
              placeholder={props.widgetTitle}
            />
          </label>
          <label>
            <span>Accent color</span>
            <input
              type="text"
              onChange={e => props.setAccentColor(e.target.value)}
              placeholder={props.accentColor}
            />
          </label>
          <label>
            <span>Widget corner radius (px)</span>
            <input
              type="text"
              onChange={e => props.setWidgetCornerRadius(e.target.value)}
              placeholder={props.widgetCornerRadius}
            />
          </label>
          <Button disabled={props.isLoading}>Save</Button>
        </form>
      </div>
    </div>
  );
};

export default WidgetCustomizationForm;
