import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import { useMutation, useQueryClient } from 'react-query';
import Button from '../../core/components/Button';
import ErrorMessage from '../../core/components/ErrorMessage';
import { postTag } from '../../core/api/api';

const useStyles = createUseStyles((theme) => ({
  title: {
    fontFamily: theme.font.brand,
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },
}));

const TagNew = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tagName, setTagName] = useState();
  const [tagColor, setTagColor] = useState();
  const { project_id } = useParams();

  const queryClient = useQueryClient();

  const { mutate, error, isLoading } = useMutation(postTag, {
    onSuccess: (data) => {
      queryClient.invalidateQueries();
      navigate(`/projects/${project_id}/labels`);
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    mutate({
      project_id,
      body: {
        name: tagName,
        color: tagColor
      }
    });
  }

  return (
    <div className={classes.page}>
      <form onSubmit={handleSubmit}>
        <div className={classes.title}>Create a new label</div>
        <ErrorMessage error={error} customMessagesKey='onboarding-tag' />
        <label>
          <span>Label name</span>
          <input type="text" onChange={e => setTagName(e.target.value)}/>
        </label>
        <label>
          <span>Label color</span>
          <input type="text" onChange={e => setTagColor(e.target.value)}/>
        </label>
        <Button disabled={isLoading}>Create</Button>
      </form>
    </div>
  );
};

export default TagNew;
