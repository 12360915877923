import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',

    '& div': {
      fontSize: 32,
      marginBottom: 24,
      color: '#bbb',
    },

    '& a': {
      fontSize: 18,
      color: '#bbb',
    },
  },
});

const RootErrorPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.errorWrapper}>
      <div>Oops! Something went wrong...</div>
      <Link to="/">Take me back to dashboard</Link>
    </div>
  )
};

export default RootErrorPage;
