import { createUseStyles } from 'react-jss';

import invert from 'invert-color';

import ImgSampleImage from '../../images/widget-sample-image.png';
import ImgIconCalendar from '../../images/icon-calendar.png';
import ImgLogoNotiSmallWhite from '../../images/logo-noti-small-white.png';

const useStyles = createUseStyles((theme) => ({
  widget: {
    background: theme.color.white,
    color: theme.color.dark,
    maxWidth: 456,
    borderRadius: 8,
    overflow: 'hidden'
  },

  widgetHeader: {
    backgroundColor: theme.color.blue,
    color: theme.color.greyLight,
    fontSize: 18,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 20,
    position: 'relative',
    lineHeight: '100%'
  },

  widgetHeaderTitle: {
    flexGrow: '1',
    fontWeight: '700'
  },

  widgetBody: {
    backgroundColor: theme.color.white,
    color: 'rgba(6, 26, 36, 0.7)',
    height: 'calc(100vh - 750px)',
    minHeight: 400,
    overflow: 'hidden',
    overflowY: 'scroll',

    [theme.breakpoints.lg]: {
      height: 'calc(100vh - 208px)',
    }
  },

  widgetPost: {
    padding: [24, 20],

    '& + &': {
      borderTop: `1px solid ${theme.color.grey}`
    }
  },

  widgetPostDate: {
    color: 'rgba(6, 26, 36, 0.5)',
    letterSpacing: '-0.005em',
    fontWeight: 700,
    fontSize: 12,
    marginBottom: 16,
    display: 'flex',

    '& > img': {
      marginRight: 6
    }
  },

  widgetPostTitle: {
    color: theme.color.dark,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '110%',
    marginBottom: 16
  },

  widgetPostImage: {
    '& > img': {
      width: '100%',
      marginBottom: 16
    }
  },

  widgetPostBody: {
    color: 'rgba(6, 26, 36, 0.7)',
    letterSpacing: '-0.005em',
    fontSize: 15,
    lineHeight: '165%',
  },

  widgetFooter: {
    backgroundColor: theme.color.dark,
    color: theme.color.white,
    fontSize: 13,
    lineHeight: '100%',
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  widgetPostTags: {
    marginBottom: 16,
  },

  postTag: {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '4px 8px',
    marginRight: '4px',
    borderRadius: '5px',
    background: '#F1F1F1',
    fontSize: '12px',
  }
}));

const today = new Date();
const options = { year: 'numeric', month: 'short', day: 'numeric' };
const todayDate = today.toLocaleDateString('en-GB', options);

const posts = [
  {
    id: 3,
    date: '21 Sept 2022',
    title: 'Widget title can be changed!',
    tags: [
      { id: 1, name: 'update', color: '#4f6' },
      { id: 2, name: 'fix', color: '#fe7' },
      { id: 3, name: 'new', color: 'lightblue' },
    ],
    body: `
      Some say colors are companies’ soul – we don’t want you to lose your soul,
      that is why we introduce: Primary color customization 🎨 Go to settings &
      find a convenient color picket to choose your company HEX  🍭`
  },
  {
    id: 2,
    date: '21 Sept 2022',
    title: 'Primary color customization',
    tags: [{ id: 1, name: 'update', color: '#4f6' }],
    body: `
      Some say colors are companies’ soul – we don’t want you to lose your soul,
      that is why we introduce: Primary color customization 🎨 Go to settings &
      find a convenient color picket to choose your company HEX  🍭`
  },
  { id: 1, date: '21 Sept 2022', title: 'Noti release party', tags: [], body: 'No updates...' },
]

const WidgetPreview = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.widget} style={{ 'borderRadius': props.widgetCornerRadius + 'px' }}>

      <div className={classes.widgetHeader} style={{ 'backgroundColor': props.accentColor }}>
        <div className={classes.widgetHeaderTitle}>
          {props.widgetTitle}
        </div>
      </div>

      <div className={classes.widgetBody}>

        <div className={classes.updates}>
          {(!!props.widgetPostTitle || !!props.widgetPostBody) && (
            <div className={classes.widgetPost}>
              <div className={classes.widgetPostDate}>
                <img src={ImgIconCalendar} width="12" />
                <div>{todayDate}</div>
              </div>
              <div className={classes.widgetPostTitle}>{props.widgetPostTitle}</div>
              <div className={classes.widgetPostBody}>{props.widgetPostBody}</div>
            </div>
          )}
          {posts.map(post => (
            <div key={post.id} className={classes.widgetPost}>
              <div className={classes.widgetPostDate}>
                <img src={ImgIconCalendar} width="12" />
                <div>{post.date}</div>
              </div>
              <div className={classes.widgetPostTitle}>{post.title}</div>
              <div className={classes.widgetPostTags}>
                {post.tags.map((tag) => {
                  let tagColor;

                  try {
                    tagColor = invert(tag.color, true);
                  } catch {
                    tagColor = 'black';
                  }
                  return (
                    <div
                      key={tag.id}
                      className={classes.postTag}
                      style={{ backgroundColor: tag.color, color: tagColor }}
                    >{tag.name}</div>
                  )
                })}
              </div>
              {/* <div className={classes.widgetPostImage}>
                <img src={ImgSampleImage} />
              </div> */}
              <div className={classes.widgetPostBody}>{post.body}</div>
            </div>
          ))}
        </div>

      </div>

      <div className={classes.widgetFooter}>
        <div>Powered by</div>
        <img src={ImgLogoNotiSmallWhite} width="45" />
      </div>
    </div>
  )
};

export default WidgetPreview;
