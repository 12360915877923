import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";

import { EDITOR_JS_TOOLS } from './tools'

const DEFAULT_INITIAL_DATA = {
  "time": new Date().getTime(),
  "blocks": [
    {
      "type": "paragraph",
      "data": { "text": "..." }
    },
  ]
}

const EditorJSComponent = ({ initialData, onContentChange }) => {
  const ejInstance = useRef(null);

  const initEditor = () => {
    const editor = new EditorJS({
      holder: 'editorjs',
      onReady: () => {
        ejInstance.current = editor;
      },
      autofocus: true,
      placeholder: 'Your post content...',
      logLevel: 'ERROR',
      data: initialData ? initialData : DEFAULT_INITIAL_DATA,
      onChange: async () => {
        let content = await editor.saver.save();

        if (onContentChange) {
          onContentChange(content);
        }
      },
      tools: EDITOR_JS_TOOLS,
    });
  };

  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

  return <><div id='editorjs'></div></>;
}

export default EditorJSComponent;
