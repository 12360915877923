import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import EditorJSComponent from "./EditorJSComponent";
import EditorJSToHTML from "./EditorJSToHTML";

import DatePicker from "react-datepicker";
import ReactTags from 'react-tag-autocomplete';
import invert from 'invert-color';

import Button from "../../core/components/Button";
import ErrorMessage from "../../core/components/ErrorMessage";

import WidgetPreview from "../../widget/components/WidgetPreview";

import { getTags, postPost } from "../../core/api/api";

import useStyles from "./editorStyles";

function Tag({ tag, removeButtonText, onDelete }) {
  let tagColor;

  try {
    tagColor = invert(tag.color, true);
  } catch {
    tagColor = 'black';
  }

  return (
    <button
      type='button'
      className='react-tags__selected-tag'
      title={`${removeButtonText}: ${tag.name}`}
      onClick={onDelete}
      style={{ backgroundColor: tag.color, color: tagColor }}
    >
      {tag.name}
    </button>
  )
}

const Editor = () => {
  const classes = useStyles()
  const navigate = useNavigate();

  const { project_id } = useParams();
  const queryClient = useQueryClient();

  const [title, setTitle] = useState();
  const [publishAt, setPublishAt] = useState();
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [widgetPostBody, setWidgetPostBody] = useState();

  const widgetTitle = 'Widget Preview';
  const accentColor = '#3E5CEB';
  const widgetCornerRadius = 8;

  const { data: dbSuggestions } = useQuery('tags', () => getTags({ project_id }));

  const { mutate, isLoading, error } = useMutation(postPost, {
    onSuccess: () => {
      queryClient.invalidateQueries('posts')
      navigate(`/projects/${project_id}/posts`)
    }
  });

  useEffect(() => {
    if (dbSuggestions) {
      setSuggestions(dbSuggestions);
    }
  }, [dbSuggestions]);

  const reactTags = useRef();

  const onAddition = useCallback((newTag) => {
    let newTagObject = suggestions.find((suggestion) => {
      if (suggestion && newTag) {
        return suggestion?.name === newTag?.name
      }
    }) || newTag;
    setTags([...tags, newTagObject])
  }, [tags, suggestions]);

  const onDelete = useCallback((tagIndex) => {
    setTags(tags.filter((_, i) => i !== tagIndex))
  }, [tags])

  const blocksToHTML = (blocksContent) => {
    if (!blocksContent) {
      return null;
    }
    return <EditorJSToHTML data={blocksContent} />;
  }

  const handlePublish = async () => {
    mutate({
      project_id,
      body: {
        title,
        body: JSON.stringify(widgetPostBody),
        date: new Date().toISOString().split('T')[0],
        publish_at: publishAt,
        tags,
      }
    });
  }

  if (isLoading) {
    return 'Please wait...';
  }

  return (
    <div className={classes.editor}>
      <div className={classes.twoColumns}>
        <div className={classes.leftSide}>
          <ErrorMessage error={error} customMessagesKey='editor' />

          <div className={classes.editorTitle}>
            <input
              autoFocus
              placeholder="Untitled Post"
              required
              type="text"
              onChange={e => setTitle(e.target.value)}
            />
          </div>

          <div className="editor-wrapper">
            <EditorJSComponent onContentChange={setWidgetPostBody} />
          </div>

          {/* <div className={classes.settings}>
          <div className={classes.field}>
            <div className={classes.label}>
              <label>Set custom date and time when you want to publish this post:</label>
              <div className={classes.labelOptional}>OPTIONAL</div>
            </div>
            <DatePicker
              selected={publishAt}
              onChange={(date) => setPublishAt(date)}
              showTimeSelect
              dateFormat="Pp"
              placeholderText="Schedule publish date and time"
            />
          </div>

          <div className={classes.field}>
            <div className={classes.label}>
              <label>Add labels:</label>
              <div className={classes.labelOptional}>OPTIONAL</div>
            </div>
            <ReactTags
              ref={reactTags}
              allowNew
              tags={tags}
              suggestions={suggestions}
              onDelete={onDelete}
              onAddition={onAddition}
              delimiters={['Enter', 'Tab', ' ']}
              placeholderText={"Labels (optional)"}
              tagComponent={Tag}
            />
          </div>
        </div> */}

          <div className={classes.buttons}>
            <Button onClick={handlePublish}>Publish</Button>
            {/* <Button onClick={handlePublish}>Schedule</Button> */}
          </div>
        </div>

        <div className={classes.rightSide}>
          <div className={classes.widgetPreview}>
            <WidgetPreview
              widgetTitle={widgetTitle}
              accentColor={accentColor}
              widgetCornerRadius={widgetCornerRadius}
              widgetPostTitle={title}
              widgetPostBody={blocksToHTML(widgetPostBody)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editor
