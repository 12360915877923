import { createUseStyles } from "react-jss";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { getProjects } from "../core/api/api";
import { useEffect } from "react";

const useStyles = createUseStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    backgroundColor: theme.color.greyLight,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    paddingBottom: 8,
    lineHeight: 1.5,

    [theme.breakpoints.md]: {
      padding: 40,
    },

    '& h2': {
      fontSize: 20,
      marginBottom: 32,

      [theme.breakpoints.md]: {
        fontSize: 24,
      }
    },
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    data: projects,
    isLoading: areProjectsLoading,
    error: projectsError
  } = useQuery('projects', getProjects);

  useEffect(() => {
    const currentProject = projects?.length > 0 ? projects[0] : null;
    if (currentProject) {
      navigate(`/projects/${currentProject.id}/posts`);
    }
  }, [projects]);

  return null;
};

export default Dashboard;
