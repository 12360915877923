import { useQuery } from 'react-query';

import { useNavigate, NavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { getCurrentUser, getProjects, getCompany } from '../../core/api/api';
import useAuth from '../../core/hooks/useAuth';

import ImgLogo from '../../images/logo.png';
import OffIcon from '../../images/off.png';

const useStyles = createUseStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,

    backgroundColor: '#F0EBE6',
    color: theme.color.dark,
    width: 180,
    position: 'relative',
    minHeight: '100vh',
    borderRight: '1px solid #E4E6E8',
    flexShrink: 0,

    [theme.breakpoints.sm]: {
      paddingLeft: 32,
      paddingRight: 32,
      width: 220,
    },

    [theme.breakpoints.md]: {
      width: 300,
    }
  },

  '@media (min-width: 1920px)': {
    sidebar: {
      width: 404
    }
  },

  logo: {
    marginTop: 24,
    marginBottom: 24,
    fontWeight: '700',
    fontSize: 30,
    letterSpacing: 2,
    alignSelf: 'flex-start',

    '& a': {
      color: 'black'
    }
  },

  organisation: {
    height: 64,
    background: '#FFFFFF',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: [14, 16],

    '& div:first-child': {
      fontWeight: 'bold',
    },
    '& div:nth-child(2)': {
      color: '#9EA5A9',
      fontSize: 13,
      marginTop: 6
    },
  },

  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginBottom: 30
  },

  buttons: {
    marginTop: 64,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',

    '& a': {
      // background: '#F7F7F7',

      textDecoration: 'none',
      color: theme.color.dark,

      height: 40,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 16,

      borderRadius: 4,
      marginBottom: 8,
      cursor: 'pointer',

      fontWeight: '500',
      fontSize: 14,
    },

    '& a:hover': {
      // color: '#fff',
      background: '#E8E0D9',
    },

    '& a.selected': {
      // background: '#DADDDE',
      background: '#3E5CEB',
      color: '#fff'
    }
  },

  userSection: {
    // position: 'absolute',
    // bottom: 36,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'bottom',

    '& a': {
      textDecoration: 'none',
      color: theme.color.dark,
    },

    '& a, & div': {
      marginBottom: 8,
      cursor: 'pointer',
      fontWeight: '500',
      fontSize: 14,
      paddingLeft: 16,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      marginBottom: 8,
    },

    '& a:hover, div:hover': {
      background: '#E8E0D9',
    },

    '& a.selected': {
      // background: '#DADDDE',
      background: '#3E5CEB',
      color: '#fff'
    }
  },

  offIcon: {
    marginLeft: 8
  }
}));

const Sidebar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useAuth();

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    error: currentUserError
  } = useQuery('currentUser', getCurrentUser);

  const { data: company } = useQuery('company', getCompany);

  const {
    data: projects,
    isLoading: areProjectsLoading,
    error: projectsError
  } = useQuery('projects', getProjects);

  const logoutUser = async e => {
    auth.logout()
  }

  const currentProject = projects?.length > 0 ? projects[0] : null;

  const btnClassName = ({ isActive }) => isActive ? "selected" : undefined;

  return (
    <div className={classes.sidebar}>
      <div className={classes.logo}>
        <img src={ImgLogo} width="72" />
      </div>

      {currentProject && company ? (
        <div className={classes.organisation}>
          <div>{currentProject.name}</div>
          <div>{company.name}</div>
        </div>
      ) : null
      }

      <div className={classes.buttonsWrapper}>
        <div className={classes.buttons}>
          {currentProject && (
            <>
              <NavLink className={btnClassName} to={`/projects/${currentProject.id}/posts`}>Widget</NavLink>
              <NavLink className={btnClassName} to={`/projects/${currentProject.id}/labels`}>Labels</NavLink>
              <NavLink className={btnClassName} to='/widget'>Settings</NavLink>
            </>
          )
          }
        </div>

        <div className={classes.userSection}>
          <NavLink className={btnClassName} to={`/settings`}>My account</NavLink>
          <div onClick={logoutUser}>
            Log out
          </div>
        </div>
      </div>
    </div>
  )
};

export default Sidebar;
