import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMutation, useQueryClient } from 'react-query';

import Button from '../../core/components/Button';
import ErrorMessage from '../../core/components/ErrorMessage';
import { postCompany } from '../../core/api/api';

const useStyles = createUseStyles((theme) => ({
  title: {
    fontFamily: theme.font.brand,
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },
}));

const OnboardingNewCompanyForm = () => {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState();

  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(postCompany, {
    onSuccess: (data) => {
      queryClient.invalidateQueries();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    mutate({ name: companyName });
  }

  return (
    <div className={classes.page}>

      <form onSubmit={handleSubmit}>
        <div className={classes.title}>Setup company account</div>
        <ErrorMessage error={error} customMessagesKey='onboarding-company' />
        <label>
          <span>Company name</span>
          <input type="text" onChange={e => setCompanyName(e.target.value)}/>
        </label>
        <Button disabled={isLoading}>Create</Button>
      </form>

    </div>
  )
};

export default OnboardingNewCompanyForm;
