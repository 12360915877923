import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useIsFetching } from 'react-query';

const useStyles = createUseStyles((theme) => ({
  loader: {
    position: 'fixed',
    top: 16,
    left: 110,
    width: 50,
    height: 50,
    zIndex: '99999',
    overflow: 'hidden',
    transition: 'opacity 0.3s ease-in-out',
  },

  spinner: {
    width: 30,
    height: 30,
    top: 5,
    left: 5,
    position: 'relative',
    animation: '$sk-rotate 2.0s infinite linear',
  },

  dot: {
    width: 15,
    height: 15,
    position: 'absolute',
    top: '0',
    backgroundColor: '#333',
    borderRadius: '100%',
    animation: '$sk-bounce 2.0s infinite ease-in-out',
  },

  dot2: {
    top: 'auto',
    bottom: '0',
    animationDelay: '-1.0s',
  },

  '@keyframes sk-rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    }
  },

  '@keyframes sk-bounce': {
    '0%': {
      transform: 'scale(0.0)',
    },
    '50%': {
      transform: 'scale(1.0)',
    },
    '100%': {
      transform: 'scale(0.0)',
    },
  }
}));

const Loader = ({ isLoading }) => {
  const [showLoader, setShowLoader] = useState(false);
  const isFetching = useIsFetching();
  const classes = useStyles();

  useEffect(() => {
    let timer;
    if (isLoading || isFetching) {
      timer = setTimeout(() => setShowLoader(true), 1000);
    }
    return () => {
      clearTimeout(timer);
      setShowLoader(false);
    };
  }, [isLoading, isFetching]);

  return (
    <div className={classes.loader} style={{ opacity: showLoader ? 1 : 0 }}>
      <div className={classes.spinner}>
        <div className={`${classes.dot}`}></div>
        <div className={`${classes.dot} ${classes.dot2}`}></div>
      </div>
    </div>
  )
};

export default Loader;
