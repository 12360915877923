import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from "react-router-dom";

import { postSendResetPasswordEmail } from '../core/api/api';

import Button from '../core/components/Button';
import ErrorMessage from '../core/components/ErrorMessage'

const useStyles = createUseStyles((theme) => ({
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    background: theme.color.white
  },

  login: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40
  },

  loginSection: {
    color: theme.color.greyDark,
    padding: 16
  },

  title: {
    fontFamily: theme.font.brand,
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },

  subtitle: {
    color: theme.color.greyDark,
    fontSize: 15,
    marginBottom: 32
  },

  confirmation: {
    margin: 32,
    padding: 35,
    background: theme.color.greyLight,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.5,

    '& h2': {
      textAlign: 'center'
    }
  },

  text: {
    marginTop: 8,
    marginBottom: 8,
  },

  button: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center'
  },
}));

const ForgotPassword = () => {

  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error, data: emailSent } = useMutation(postSendResetPasswordEmail, {
    onSuccess: (data) => {
      queryClient.invalidateQueries();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    mutate({ email })
  }

  if (emailSent) {
    return (
      <div className={classes.page}>
        <div className={classes.confirmation}>
          <h2>Check your inbox ⏳</h2>
          <div className={classes.text}>You should receive an e-mail from us with the next steps on how to reset your password.</div>
          <div className={classes.button}>
            <Button onClick={e => navigate('/login')}>OK</Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={classes.page}>
        <div className={classes.title}>Did you forget your password?</div>
        <div className={classes.subtitle}>No worries! Fill in your e-mail address and we will send you the next steps...</div>
        <ErrorMessage error={error} customMessagesKey='forgot-password' />
        <form onSubmit={handleSubmit}>
          <label>
            <span>Email</span>
            <input
              type="email"
              required
              onChange={e => setEmail(e.target.value)}
            />
          </label>
          <Button disabled={isLoading}>I want my account back</Button>
        </form>
        <div className={classes.login}>
          <div className={classes.loginSection}>Just remembered?</div>
          <Button onClick={e => navigate('/login')}>Log in</Button>
        </div>
      </div>
    </>
  )
};

export default ForgotPassword;
