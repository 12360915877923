import { useNavigate, useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useQuery, useQueryClient, useMutation } from "react-query";

import invert from 'invert-color';

import Button from "../../core/components/Button";
import { getProject, getPosts, deletePost } from "../../core/api/api";

import ImgIconCalendar from '../../images/icon-calendar.png';
import ImgLogoNotiSmallWhite from '../../images/logo-noti-small-white.png';
import ImgSampleImage from '../../images/widget-sample-image.png';

import { EditorJSToHTML } from '../../editor';

const useStyles = createUseStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    alignItems: 'center',

    '& h1': {
      flexGrow: 1
    }
  },

  sidebar: {
    marginLeft: '56px',
    width: '250px',
  },

  widgetPreview: {
    backgroundColor: 'rgba(62, 92, 235, 0.25)',
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
  },

  widget: {
    background: theme.color.white,
    color: theme.color.dark,
    maxWidth: 456,
    minWidth: 456,
    borderRadius: 8,
    overflow: 'hidden'
  },

  widgetHeader: {
    backgroundColor: theme.color.blue,
    color: theme.color.greyLight,
    fontSize: 18,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 20,
    position: 'relative',
    lineHeight: '100%'
  },

  widgetHeaderTitle: {
    flexGrow: '1',
    fontWeight: '700'
  },

  icons: {
    display: 'flex',
    position: 'absolute',
    top: '24px',
    right: '24px',

    '& div': {
      cursor: 'pointer',
      marginLeft: '16px'
    }
  },

  noPostsWrapper: {
    backgroundColor: theme.color.greyLight,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,

    '& h1': {
      fontSize: 24,
      marginBottom: 16
    },

    '& p': {
      fontSize: 18,
      lineHeight: 1.6,
      textAlign: 'center'
    },

    '& button': {
      marginTop: 24
    },
  },

  widgetBody: {
    backgroundColor: theme.color.white,
    color: 'rgba(6, 26, 36, 0.7)',
    height: 'calc(100vh - 750px)',
    minHeight: 400,
    overflow: 'hidden',
    overflowY: 'scroll',

    [theme.breakpoints.lg]: {
      height: 'calc(100vh - 208px)',
    }
  },

  widgetPost: {
    padding: [24, 20],
    position: 'relative',

    '& + &': {
      borderTop: `1px solid ${theme.color.grey}`
    }
  },

  widgetPostDate: {
    color: 'rgba(6, 26, 36, 0.5)',
    letterSpacing: '-0.005em',
    fontWeight: 700,
    fontSize: 12,
    marginBottom: 16,
    display: 'flex',

    '& > img': {
      marginRight: 6
    }
  },

  widgetPostTitle: {
    color: theme.color.dark,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '110%',
    marginBottom: 16
  },

  widgetPostImage: {
    '& > img': {
      width: '100%',
      marginBottom: 16
    }
  },

  widgetPostBody: {
    color: 'rgba(6, 26, 36, 0.7)',
    letterSpacing: '-0.005em',
    fontSize: 15,
    lineHeight: '165%',
  },

  widgetFooter: {
    backgroundColor: theme.color.dark,
    color: theme.color.white,
    fontSize: 13,
    lineHeight: '100%',
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  widgetPostTags: {
    marginBottom: 16,
  },

  postTag: {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '4px 8px',
    marginRight: '4px',
    borderRadius: '5px',
    background: '#F1F1F1',
    fontSize: '12px',
  }
}));

const Posts = () => {
  const classes = useStyles();
  const { project_id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: posts, error } = useQuery('posts', () => getPosts({ project_id }));

  const { data: project, projectError } = useQuery(
    ['project', project_id],
    () => getProject({ project_id })
  );

  const { mutate } = useMutation(deletePost, {
    onSuccess: () => {
      queryClient.invalidateQueries('posts');
      navigate(`/projects/${project_id}/posts`);
    }
  });

  if (error) {
    return 'An error has occurred: ' + error.message;
  }

  if (projectError) {
    return 'An error has occurred: ' + projectError.message;
  }

  const loadUpdateHTML = (update) => {
    const data = JSON.parse(update.body);
    return <EditorJSToHTML data={data} />;
  }

  const removePost = postId => mutate({
    id: postId,
    project_id
  })

  if (!posts?.length) {
    return (
      <div className={classes.page}>
        <h1>Widget</h1>
        <div className={classes.noPostsWrapper}>
          <h2>Let users know 💛</h2>
          <p>
            Write your first post whether it is a new feature or a bug fix.<br />
            Your users will love to know what are you doing for them.
          </p>
          <Button onClick={() => navigate(`/projects/${project_id}/posts/new`)}>Write your first post</Button>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <h1>Widget</h1>
        <div className={classes.actions}>
          <Button onClick={() => navigate(`/projects/${project_id}/posts/new`)}>New Post</Button>
        </div>
      </div>
      <div className={classes.widgetPreview}>
        <div className={classes.widget}>
          <div className={classes.widgetHeader}>
            <div className={classes.widgetHeaderTitle}>
              Widget Posts asdasd
            </div>
          </div>

          {posts?.map(post => (
            <div key={post.id} className={classes.widgetPost}>

              <div className={classes.widgetPostDate}>
                <img src={ImgIconCalendar} width="12" />
                <div>{post.date}</div>
              </div>

              <div className={classes.widgetPostTitle}>{post.title}</div>

              <div className={classes.widgetPostTags}>
                {post.tags.map((tag) => {
                  let tagColor;

                  try {
                    tagColor = invert(tag.color, true);
                  } catch {
                    tagColor = 'black';
                  }
                  return (
                    <div
                      key={tag.id}
                      className={classes.postTag}
                      style={{ backgroundColor: tag.color, color: tagColor }}
                    >{tag.name}</div>
                  )
                })}
              </div>

              <div className={classes.widgetPostBody}>
                {loadUpdateHTML(post)}
              </div>

              <div className={classes.icons}>
                <div onClick={() => navigate(`/projects/${project_id}/posts/${post.id}/edit`)}>
                  <img src='https://cdn-icons-png.flaticon.com/512/61/61456.png' width="16" />
                </div>

                <div onClick={() => removePost(post.id)}>
                  <img src='https://cdn-icons-png.flaticon.com/512/3096/3096687.png' width="16" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Posts;
