import { createUseStyles } from 'react-jss';
import { useQuery } from "react-query";

import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';

import { getPosts } from "../../core/api/api";

const useStyles = createUseStyles((theme) => ({
  widget: {
    background: theme.color.greyLight,
    color: theme.color.dark,
    height: '100vh',
    position: 'fixed',
    right: '0',
    width: '500px',
    overflowY: 'scroll',
    padding: '24px',
    borderLeft: '1px solid #ddd',
    zIndex: '9999',

    '& h2': {
      textAlign: 'center',
      margin: '32px 0',
    }
  },

  post: {
    background: theme.color.white,
    padding: '4px 15px',
    marginBottom: '16px',
    borderRadius: '5px'
  },

  closeWidget: {
    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    right: '15px'
  }
}));

const Widget = (props) => {
  const classes = useStyles();

  const { data: posts, error } = useQuery('posts', getPosts);

  if (error) {
    return 'An error has occurred: ' + error.message;
  }

  const createMarkup = (html) => {
    return  {
      __html: DOMPurify.sanitize(html)
    }
  }

  const loadUpdateHTML = update =>
    createMarkup(convertToHTML(convertFromRaw(JSON.parse(update.body)['content'])))

  const closeWidget = () => props.setIsWidgetVisible(false);

  return (
    <div className={classes.widget}>
      <div onClick={() => closeWidget()} className={classes.closeWidget}>
        <img src='https://cdn-icons-png.flaticon.com/512/271/271203.png' width="12" />
      </div>
      <h2>Latest Posts</h2>
      <div className={classes.updates}>
        { posts.map(post => (
          <div key={post.id} className={classes.post}>
            <p>{ post.date }</p>
            <h3>{ post.title }</h3>
            <div
              className="preview"
              dangerouslySetInnerHTML={loadUpdateHTML(post)}
            ></div>
          </div>
        ))}
      </div>
    </div>
  )
};

export default Widget;
