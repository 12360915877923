import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import {
  useLocation,
  useNavigate,
} from "react-router-dom";

import useAuth from '../../../core/hooks/useAuth';

import Button from '../../../core/components/Button';
import ErrorMessage from '../../../core/components/ErrorMessage'

import { postToken } from '../../../core/api/api';

const useStyles = createUseStyles((theme) => ({
  title: {
    fontFamily: theme.font.brand,
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },

  labelRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  forgotPasswordLink: {
    fontSize: 14,
    color: theme.color.grey,

    '&:hover': {
      color: theme.color.greyDark,
    }
  }
}));

const LoginForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthToken } = useAuth();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(postToken, {
    onSuccess: (data) => {
      setAuthToken(data.access_token);
      queryClient.invalidateQueries();
      const locationFrom = location.state ? location.state.from.pathname : '/'
      navigate(locationFrom, { replace: true });
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    mutate(new URLSearchParams({
      username,
      password,
      grant_type: 'password',
    }))
  }

  return (
    <>
      <div className={classes.title}>Log in</div>
      <ErrorMessage error={error} customMessagesKey='login' />
      <form onSubmit={handleSubmit}>
        <label>
          <span>Email</span>
          <input
            type="email"
            autoFocus
            required
            onChange={e => setUserName(e.target.value)}
          />
        </label>
        <label>
          <div className={classes.labelRow}>
            <span>Password</span>
            <Link
              to='/forgot-password'
              className={classes.forgotPasswordLink}
              tabIndex="-1"
            >Forgot password?</Link>
          </div>
          <input
            type="password"
            name="password"
            required
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        <Button disabled={isLoading}>Log in</Button>
      </form>
    </>
  )
};

export default LoginForm;
