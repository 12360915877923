import React from 'react';
import { useNavigate } from "react-router-dom";
import { createUseStyles } from 'react-jss';

import ImgWaves from '../../../images/waves.png';
import ImgCloseIcon from '../../../images/close_blue.png';
import ImgLogo from '../../../images/logo.png';

import Button from '../../../core/components/Button'
import LoginForm from './LoginForm'

const useStyles = createUseStyles((theme) => ({
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    background: theme.color.white,
  },

  signup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40
  },

  signupInfo: {
    color: theme.color.greyDark,
    padding: 16
  },

  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    marginTop: 72,

    [theme.breakpoints.md]: {
      width: '50vw',
    },
  },

  rightSide: {
    display: 'none',
    width: '50vw',
    backgroundColor: '#CCFB4B',
    overflow: 'hidden',
    position: 'relative',

    [theme.breakpoints.md]: {
      display: 'flex',
    },

    '& > img': {
      height: '100vh',
      width: '50vw',
    }
  },

  infoBoxes: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  infoBox: {
    backgroundColor: 'white',
    borderRadius: 10,
    minHeight: 100,
    width: 400,
    marginBottom: 40,
    padding: 24,

    [theme.breakpoints.lg]: {
      width: 500,
    },
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,

    '& > img': {
      marginRight: 8
    }
  },

  desc: {
    fontSize: 15,
    lineHeight: 1.5
  },

  logo: {
    marginTop: 72,
  }
}));

const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return(
    <div className={classes.page}>
      <div className={classes.leftSide}>
        <LoginForm />
        <div className={classes.signup}>
          <div className={classes.signupInfo}>No account yet?</div>
          <Button onClick={e => navigate('/signup')}>Sign up</Button>
        </div>
        <a href="https://noti.so">
          <img className={classes.logo} src={ImgLogo} width="72" />
        </a>
      </div>
      <div className={classes.rightSide}>
        <img src={ImgWaves} />
        <div className={classes.infoBoxes}>
          <div className={classes.infoBox}>
            <div className={classes.title}>
              <img src={ImgCloseIcon} width="25" />
              Product updates
            </div>
            <div className={classes.desc}>
              Notify users about new product features, ongoing improvements, bug fixes, and exciting product news.
            </div>
          </div>
          <div className={classes.infoBox}>
            <div className={classes.title}>
              <img src={ImgCloseIcon} width="25" />
              Marketing updates
            </div>
            <div className={classes.desc}>
              Share special promotions & marketing activities with your users. Generate growth with the Noti widget.
            </div>
          </div>
          <div className={classes.infoBox}>
            <div className={classes.title}>
              <img src={ImgCloseIcon} width="25" />
              Content updates
            </div>
            <div className={classes.desc}>
              Create engagement around your content pieces. Make your users up to date with your content releases.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
