import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  button: {
    appearance: 'none',
    border: 'none',
    outline: 'none',
    background: theme.color.primary,
    borderRadius: 6,
    cursor: 'pointer',
    height: 40,

    '&:hover': {
      background: theme.color.primaryDark,
    }
  },

  label: {
    color: theme.color.white,
    fontSize: 16,
  }
}));

const ButtonSmall = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={props.onClick}>
      <span className={classes.label}>{ children }</span>
    </button>
  )
};

export default ButtonSmall;
