import { useQuery } from "react-query";
import { createUseStyles } from "react-jss";

import { getProjects } from '../../core/api/api';

const useStyles = createUseStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column'
  },

  widgetDescription: {
    backgroundColor: '#fff',
    color: theme.color.dark,
    marginBottom: 20,
    borderRadius: 10
  },

  highlighted: {
    color: theme.color.yellow,
  },

  widgetCode: {
    backgroundColor: '#222',
    color: '#fff',
    padding: [5, 25],
    lineHeight: 1.4,
    borderRadius: 5,
    fontSize: 14,

    '& > pre': {
      whiteSpace: 'pre-wrap',
    }
  }
}));

const WidgetSetup = () => {
  const classes = useStyles();

  const { data: projects } = useQuery('projects', getProjects);
  const currentProject = projects?.length > 0 ? projects[0] : null;
  const urlHostname = window.location.protocol + "//" + window.location.host;

  const code = `<script>
  window.noti = (window.noti || { qw: [], qe: [],
    add: function(w) { window.noti.qw.push(w); },
    on: function(e, fn) { window.noti.qe.push([e, fn]); }
  });

  window.noti.add({
    url: "${urlHostname}/widget/${currentProject?.api_key}",
    id: "${currentProject?.api_key}",
    selector: "noti-widget",
  });
</script>

<script async src="${urlHostname}/widget.js"></script>`;

  return (
    <div className={classes.page}>
      <h3>Widget</h3>
      <div className={classes.widget}>
        <div className={classes.widgetDescription}>
          Copy and paste the script below right before the <span className={classes.highlighted}>{`</body>`}</span> tag.
        </div>
        <div className={classes.widgetCode}>
          <pre>{ code }</pre>
        </div>
      </div>
    </div>
  );
};

export default WidgetSetup;
