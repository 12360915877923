import React from 'react';
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import DOMPurify from 'dompurify';
import invert from 'invert-color';

import { getWidgetConfig, getWidgetPosts } from "../../core/api/api";

import EditorJSToHTML from '../../editor/components/EditorJSToHTML';

import ImgIconCalendar from '../../images/icon-calendar.png';
import ImgLogoNotiSmallWhite from '../../images/logo-noti-small-white.png';

const useStyles = createUseStyles((theme) => ({
  widget: {
    background: theme.color.white,
    color: theme.color.dark,
    maxWidth: 456,
    borderRadius: 8,
    overflow: 'hidden',
    margin: [0, 'auto'],
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },

  widgetHeader: {
    backgroundColor: theme.color.blue,
    color: theme.color.greyLight,
    fontSize: 18,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 20,
    position: 'relative',
    lineHeight: '100%'
  },

  widgetHeaderTitle: {
    flexGrow: '1',
    fontWeight: '700'
  },

  widgetBody: {
    backgroundColor: theme.color.white,
    color: 'rgba(6, 26, 36, 0.7)',
    height: 'calc(100vh - 208px)',
    overflow: 'hidden',
    overflowY: 'scroll',
    flexGrow: '1'
  },

  widgetPost: {
    padding: [24, 20],

    '& + &': {
      borderTop: `1px solid ${theme.color.grey}`
    }
  },

  widgetPostDate: {
    color: 'rgba(6, 26, 36, 0.5)',
    letterSpacing: '-0.005em',
    fontWeight: 700,
    fontSize: 12,
    marginBottom: 16,
    display: 'flex',

    '& > img': {
      marginRight: 6
    }
  },

  widgetPostTitle: {
    color: theme.color.dark,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '110%',
    marginBottom: 16
  },

  widgetPostImage: {
    '& > img': {
      width: '100%',
      marginBottom: 16
    }
  },

  widgetPostBody: {
    color: 'rgba(6, 26, 36, 0.7)',
    letterSpacing: '-0.005em',
    fontSize: 15,
    lineHeight: '165%',
  },

  widgetFooter: {
    backgroundColor: theme.color.dark,
    color: theme.color.white,
    fontSize: 13,
    lineHeight: '100%',
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  widgetPostTags: {
    marginBottom: 16,
  },

  postTag: {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '4px 8px',
    marginRight: '4px',
    borderRadius: '5px',
    background: '#F1F1F1',
    fontSize: '12px',
  }
}));

const WidgetPreviewPage = () => {
  const classes = useStyles();
  const { widget_code } = useParams();

  const { data: posts } = useQuery('widgetPosts', () => getWidgetPosts({ widget_code }));
  const { data: config } = useQuery('widgetConfig', () => getWidgetConfig({ widget_code }));

  const widgetTitle = config?.widget_title || 'Product Notifications'
  const accentColor = config?.widget_accent_color || '#3E5CEB'
  const widgetCornerRadius = `${config?.widget_corner_radius}` || '8'

  const blocksToHTML = (blocksContent) => {
    if (!blocksContent) {
      return null;
    }
    return <EditorJSToHTML data={blocksContent} />;
  }

  const loadPostBodyHTML = post => blocksToHTML(JSON.parse(post.body));

  return (
    <div className={classes.widget} style={{ 'borderRadius': widgetCornerRadius + 'px' }}>

      <div className={classes.widgetHeader} style={{ 'backgroundColor': accentColor }}>
        <div className={classes.widgetHeaderTitle}>
          {widgetTitle}
        </div>
      </div>

      <div className={classes.widgetBody}>

        <div className={classes.updates}>
          {posts?.map(post => (
            <div key={post.id} className={classes.widgetPost}>
              <div className={classes.widgetPostDate}>
                <img src={ImgIconCalendar} width="12" />
                <div>{post.date}</div>
              </div>
              <div className={classes.widgetPostTitle}>{post.title}</div>
              <div className={classes.widgetPostTags}>
                {post.tags.map((tag) => {
                  let tagColor;

                  try {
                    tagColor = invert(tag.color, true);
                  } catch {
                    tagColor = 'black';
                  }
                  return (
                    <div
                      key={tag.id}
                      className={classes.postTag}
                      style={{ backgroundColor: tag.color, color: tagColor }}
                    >{tag.name}</div>
                  )
                })}
              </div>
              <div className={classes.widgetPostBody}>{loadPostBodyHTML(post)}</div>
            </div>
          ))}
        </div>

      </div>

      <div className={classes.widgetFooter}>
        <div>Powered by</div>
        <img src={ImgLogoNotiSmallWhite} width="45" />
      </div>
    </div>
  );
};

export default WidgetPreviewPage;
