import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  widgetPageNav: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  tabs: {
    fontSize: 15,
    color: theme.color.dark,
    fontWeight: 700,
    display: 'flex',
    borderBottom: `1px solid ${theme.color.grey}`,
    marginBottom: 40,
    cursor: 'pointer',

    '& > div': {
      paddingBottom: 20,
      marginRight: 20
    }
  },

  activeTab: {
    paddingBottom: 20,
    borderBottom: `3px solid ${theme.color.dark}`
  }
}));

const WidgetPageNav = ({ currentTab, setCurrentTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.widgetPageNav}>
      <h1>Settings</h1>
      <div className={classes.tabs}>
        <div
          onClick={() => setCurrentTab('setup')}
          className={currentTab == 'setup' ? classes.activeTab : undefined}
        >Setup</div>
        <div
          onClick={() => setCurrentTab('customization')}
          className={currentTab == 'customization' ? classes.activeTab : undefined}
        >Customization</div>
      </div>
    </div>
  );
};

export default WidgetPageNav;
