import { createUseStyles } from "react-jss";
import { useQuery } from 'react-query';

import { getAdminStats } from '../core/api/api';


const useStyles = createUseStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    backgroundColor: theme.color.greyLight,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    paddingBottom: 8,
    lineHeight: 1.5,

    [theme.breakpoints.md]: {
      padding: 40,
    },

    '& h2': {
      fontSize: 20,
      marginBottom: 32,

      [theme.breakpoints.md]: {
        fontSize: 24,
      }
    },
  }
}));

const Admin = () => {
  const classes = useStyles();

  const { data, isLoading, error } = useQuery('admin', getAdminStats);

  return (
    <div className={classes.page}>
      <h1>Admin</h1>
      <div className={classes.content}>
        <h2>Welcome to your admin panel 🥷</h2>
        <h3>Number of user accounts: {data?.num_users}</h3>
        <h3>Last user created at: {data?.last_user_created_at}</h3>
        <h3>Number of customers: {data?.num_customers}</h3>
      </div>
    </div>
  )
};

export default Admin;
