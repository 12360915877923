import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import { postUser } from '../../../core/api/api';

import Button from '../../../core/components/Button'
import ErrorMessage from '../../../core/components/ErrorMessage'

const useStyles = createUseStyles((theme) => ({
  confirmation: {
    margin: 32,
    padding: 35,
    background: theme.color.greyLight,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.5,

    '& h2': {
      textAlign: 'center'
    }
  },

  text: {
    marginTop: 8,
    marginBottom: 8,
  },

  button: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
  },

  login: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40
  },

  loginInfo: {
    color: theme.color.greyDark,
    padding: 16
  },

  title: {
    fontFamily: theme.font.brand,
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 32,
  },

  subtitle: {
    color: theme.color.dark,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: '120%',
    marginBottom: 16,
    maxWidth: 500
  },

  checkboxes: {
    marginTop: 16,
    marginBottom: 32,
    fontSize: 12,
    maxWidth: 350,
  },

  checkboxAll: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: theme.color.dark,
    marginBottom: 8,

    '& > input': {
      marginTop: 2.5
    },

    '& > label': {
      margin: 0,
      marginLeft: 8,
      lineHeight: 1.4,
      display: 'inline-block',
    },
  },

  checkboxTerms: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: theme.color.greyDark,
    marginBottom: 8,
    textAlign: 'justify',

    '& > input': {
      marginTop: 2.5
    },

    '& > label': {
      margin: 0,
      marginLeft: 8,
      lineHeight: 1.4,
      display: 'inline-block',
    },
  },

  checkboxNewsletter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: theme.color.greyDark,
    textAlign: 'justify',

    '& > input': {
      marginTop: 2.5
    },

    '& > label': {
      margin: 0,
      marginLeft: 8,
      lineHeight: 1.4,
      display: 'inline-block',
    },
  }
}))

const SignupForm = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [privacyCheckboxSelected, setPrivacyCheckboxSelected] = useState(false);
  const [newsletterCheckboxSelected, setNewsletterCheckboxSelected] = useState(false);

  const navigate = useNavigate();
  const classes = useStyles();

  const { mutate, error, data: newUser } = useMutation(postUser);

  const selectAllCheckboxes = () => {
    setPrivacyCheckboxSelected(true);
    setNewsletterCheckboxSelected(true);
  }

  const signupUser = async e => {
    e.preventDefault();

    mutate({
      'first_name': name,
      'last_name': '',
      email,
      password,
      disabled: "false",
      'privacy_checkbox_selected': privacyCheckboxSelected,
      'newsletter_checkbox_selected': newsletterCheckboxSelected,
    })
  }

  const handleChangePrivacy = () => {
    setPrivacyCheckboxSelected(!privacyCheckboxSelected);
  }

  const handleChangeNewsletter = () => {
    setNewsletterCheckboxSelected(!newsletterCheckboxSelected);
  }

  if (newUser) {
    return (
      <div className={classes.confirmation}>
        <h2>Thanks for signing up! 👋</h2>
        <div className={classes.text}>Last but not least, please confirm your email address: <strong>{newUser.email}</strong></div>
        <div className={classes.text}>Head over to your inbox and click on the confirmation link... ⏳</div>
        <div className={classes.button}>
          {newUser.email.includes('@gmail.com') ? (
            <Button onClick={e => window.open('https://mail.google.com/mail/u/0/#search/from%3A(contact%40noti.so)+subject%3A(Welcome+to+Noti)')}>Open Gmail</Button>
          ) : (
            <Button onClick={e => navigate('/login')}>OK</Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={classes.title}>Get Started</div>
      <ErrorMessage error={error} customMessagesKey='signup' />
      <form onSubmit={signupUser}>
        <label>
          <span>Name</span>
          <input
            type="text"
            required
            autoFocus
            onChange={e => setName(e.target.value)}
          />
        </label>
        <label>
          <span>Email</span>
          <input
            type="email"
            required
            onChange={e => setEmail(e.target.value)}
          />
        </label>
        <label>
          <span>Password</span>
          <input
            type="password"
            name="password"
            required
            onChange={e => setPassword(e.target.value)}
          />
        </label>

        <div className={classes.checkboxes}>
          {/* <div className={classes.checkboxAll}>
            <input id="c1" type="checkbox" onClick={selectAllCheckboxes}/>
            <label htmlFor="c1">Select all</label>
          </div> */}
          <div className={classes.checkboxTerms}>
            <input
              id="c2"
              type="checkbox"
              checked={privacyCheckboxSelected}
              onChange={handleChangePrivacy}
              required
            />
            <label htmlFor="c2">I have read and agree to the platform <a href="https://noti.so/terms-of-service" target="_blank">Terms & Conditions</a> and the <a href="https://noti.so/privacy-policy" target="_blank">Privacy Policy</a>.</label>
          </div>
          {/* <div className={classes.checkboxNewsletter}>
            <input
              id="c3"
              type="checkbox"
              checked={newsletterCheckboxSelected}
              onChange={handleChangeNewsletter}
            />
            <label htmlFor="c3">I consent to the processing of my personal data by the owner of NOTI.SO platform for marketing purposes, in accordance with NOTI <a href="https://noti.so/privacy-policy" target="_blank">Privacy Policy</a> I am aware that I will receive commercial information about NOTI services and offers. I was informed that I can withdraw my consent at any given time.</label>
          </div> */}
        </div>

        <Button>Create Free Account</Button>

      </form>
      <div className={classes.login}>
        <div className={classes.loginInfo}>Already have an account?</div>
        <Button onClick={e => navigate('/login')}>Log in</Button>
      </div>
    </>
  )
};

export default SignupForm;
