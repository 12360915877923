import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../core/hooks/useAuth';

const useStyles = createUseStyles((theme) => ({
  header: {
    display: 'flex',
    background: theme.color.white,
    color: theme.color.dark,
    height: 72,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 32,
    borderBottom: `1px solid ${theme.color.grey}`
  },

  userIcon: {
    backgroundColor: 'black',
    width: 40,
    height: 40,
    borderRadius: 48,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    marginLeft: 32,
    marginRight: 24,
    cursor: 'pointer'
  },

  userEmail: {
    marginRight: 24
  },

  menu: {
    backgroundColor: theme.color.greyLight,
    position: 'absolute',
    top: 80,
    right: 72,
    borderRadius: [16, 0, 16, 16],
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: 14,

    '& > div': {
      padding: [8, 24],
      cursor: 'pointer',
      borderRadius: 4,

      '&:hover': {
        backgroundColor: theme.color.grey,
      }
    }
  }
}));

const Menu = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.menu}>
      <div onClick={() => navigate('/settings')}>My account</div>
      <div onClick={props.logoutUser}>Logout</div>
    </div>
  )
}

const Header = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const logoutUser = async e => {
    auth.logout()
  }

  return null;
};

export default Header;
