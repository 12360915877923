const editorJSToJSX = (data) => {
  return data.blocks.map((block, index) => {
    const blockType = block.type;
    const blockData = block.data;
    const blockText = blockData.text?.replace(/<a /gi, "<a target='_parent' ");

    switch (blockType) {
      case 'header':
        const HeaderTag = `h${blockData.level}`;
        return <HeaderTag key={index} dangerouslySetInnerHTML={{ __html: blockText }} />;

      case 'paragraph':
        return <p key={index} dangerouslySetInnerHTML={{ __html: blockText }} />;

      default:
        return null;
    }
  });
};

const EditorJSToHTML = ({ data }) => {
  return (
    <div>{editorJSToJSX(data)}</div>
  );
};

export default EditorJSToHTML;
