import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { createUseStyles } from 'react-jss';
import { useState, useEffect } from 'react';
import { postUserConfirmEmail } from '../core/api/api';

import Loader from '../core/components/Loader';
import Button from '../core/components/Button'
import ErrorMessage from '../core/components/ErrorMessage'

const useStyles = createUseStyles((theme) => ({
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    background: theme.color.white
  },

  confirmation: {
    margin: 32,
    padding: 35,
    background: theme.color.greyLight,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.5,

    '& h2': {
      textAlign: 'center'
    }
  },

  text: {
    marginTop: 8,
    marginBottom: 8,
  },

  button: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center'
  },
}));

const ConfirmUserEmail = () => {
  const [ isConfirmed, setIsConfirmed ] = useState(false);
  const { verification_code } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const { mutate, error } = useMutation(postUserConfirmEmail, {
    onSuccess: (data) => {
      setIsConfirmed(true);
    }
  });

  useEffect(() => verification_code && mutate({ verification_code }), [verification_code]);

  if (isConfirmed) {
    return (
      <div className={classes.page}>
        <div className={classes.confirmation}>
          <h2>E-mail confirmed successfully! 💪</h2>
          <div className={classes.text}>Thanks for confirming your e-mail address. You should be able to log in now 👇</div>
          <div className={classes.button}>
            <Button onClick={e => navigate('/login')}>Perfect, let's log in!</Button>
          </div>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className={classes.page}>
        <ErrorMessage error={error} customMessagesKey='confirm-email' />
        <div className={classes.button}>
          <Button onClick={e => navigate('/login')}>OK</Button>
        </div>
      </div>
    )
  }

  return <Loader isLoading={true} />;
};

export default ConfirmUserEmail;
