import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'react-jss';
import { QueryClient, QueryClientProvider } from 'react-query';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { AuthProvider } from './core/contexts/AuthContext';

import Root from './app/Root'
import RootErrorPage from './app/RootErrorPage'

import RequireAuth from './auth/RequireAuth'

import App from './app/App';

import { Login } from './auth/login'
import { Signup } from './auth/signup'
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import ConfirmUserEmail from './auth/ConfirmUserEmail';
import Dashboard from './dashboard/Dashboard';
import Admin from './admin/Admin';
import { WidgetPage, WidgetPreviewPage } from './widget';
import { Settings } from './settings';
import { Project } from './projects';
import { Posts, PostNew, PostEdit } from './posts';
import { Tags, TagNew, TagEdit } from './tags';

import 'normalize.css';

const theme = {
  breakpoints: {
    sm: '@media (min-width: 600px)',
    md: '@media (min-width: 900px)',
    lg: '@media (min-width: 1200px)',
    xl: '@media (min-width: 1536px)',
  },

  color: {
    brand: '#FF220C',
    primary: '#222',
    primaryDark: '#111',
    white: '#FFF',
    dark: '#061F24',
    grey: '#DADDDE',
    greyLight: '#F7F7F7',
    greyDark: '#9EA5A9',
    yellow: '#ECBA08',
    blue: '#3E5CEB',
    error: '#ff0033',
    red: '#FF220C',
    redDark: '#D11A00',
  },

  font: {
    brand: 'lato',
    primary: 'lato'
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<RootErrorPage />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="signup" element={<Signup />} />
      <Route path="confirm/:verification_code" element={<ConfirmUserEmail />} />
      <Route path="reset/:reset_password_token" element={<ResetPassword />} />

      <Route element={<RequireAuth />}>
        <Route element={<App />}>
          <Route index element={<Dashboard />} />
          <Route path="projects/:project_id" element={<Project />} />
          <Route path="projects/:project_id/posts" element={<Posts />} />
          <Route path="projects/:project_id/posts/new" element={<PostNew />} />
          <Route path="projects/:project_id/posts/:post_id/edit" element={<PostEdit />} />
          <Route path="projects/:project_id/labels" element={<Tags />} />
          <Route path="projects/:project_id/labels/new" element={<TagNew />} />
          <Route path="projects/:project_id/labels/:tag_id/edit" element={<TagEdit />} />
          <Route path="widget" element={<WidgetPage />} />
          <Route path="settings" element={<Settings />} />
          <Route path="admin" element={<Admin />} />
        </Route>
      </Route>

      <Route path="widget/:widget_code" element={<WidgetPreviewPage />} />
    </Route>
  )
)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
