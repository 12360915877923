import axios from './axios';
import { TOKEN_NAME } from '../consts';

export const postUserConfirmEmail = async (data) => {
  const response = await axios.post(`/users/confirm?verification_code=${data.verification_code}`);
  return response.data;
}

export const getToken = async () => {
  return localStorage.getItem(TOKEN_NAME);
}

export const postToken = async (data) => {
  const response = await axios.post('/token', data, { withCredentials: true });
  return response.data;
}

export const postCompany = async (data) => {
  const response = await axios.post('/company', data);
  return response.data;
}

export const getCompany = async () => {
  const response = await axios.get('/company');
  return response.data;
}

export const getProjects = async () => {
  const response = await axios.get('/projects');
  return response.data;
}

export const getProject = async (data) => {
  const response = await axios.get(`/projects/${data.project_id}`);
  return response.data;
}

export const postProject = async (data) => {
  const response = await axios.post('/projects', data);
  return response.data;
}

export const updateProject = async (data) => {
  const response = await axios.patch(`/projects/${data.project_id}`, data.body);
  return response.data;
}

export const getPost = async (data) => {
  const response = await axios.get(`/projects/${data.project_id}/posts/${data.post_id}`);
  return response.data;
}

export const getPosts = async (data) => {
  const response = await axios.get(`/projects/${data.project_id}/posts`);
  const posts = await response.data;
  return posts.sort((a, b) => b.id - a.id);
}

export const getWidgetPosts = async (data) => {
  const response = await axios.get(`/widget/${data.widget_code}/posts`);
  const posts = await response.data;
  return posts.sort((a, b) => b.id - a.id);
}

export const getWidgetConfig = async (data) => {
  const response = await axios.get(`/widget/${data.widget_code}/config`);
  return response.data;
}

export const postPost = async (data) => {
  const response = await axios.post(`/projects/${data.project_id}/posts`, data.body);
  return response.data;
}

export const updatePost = async (data) => {
  const response = await axios.patch(`/projects/${data.project_id}/posts/${data.id}`, data.body);
  return response.data;
}

export const deletePost = async (data) => {
  const response = await axios.delete(`/projects/${data.project_id}/posts/${data.id}`);
  return response.data;
}

export const getCurrentUser = async () => {
  const response = await axios.get('/users/me');
  return response.data;
}

export const postUser = async (data) => {
  const response = await axios.post(`/users`, data);
  return response.data;
}

export const postSendResetPasswordEmail = async (data) => {
  const response = await axios.post(`/users/send-reset-password-email`, data);
  return response.data;
}

export const postResetUserPassword = async (data) => {
  const response = await axios.post(`/users/reset-password`, data);
  return response.data;
}

export const getTags = async (data) => {
  const response = await axios.get(`/projects/${data.project_id}/tags`);
  const tags = await response.data;
  return tags.sort((a, b) => b.id - a.id);
}

export const getTag = async (data) => {
  const response = await axios.get(`/projects/${data.project_id}/tags/${data.tag_id}`);
  return response.data;
}

export const postTag = async (data) => {
  const response = await axios.post(`/projects/${data.project_id}/tags`, data.body);
  return response.data;
}

export const updateTag = async (data) => {
  const response = await axios.patch(`/projects/${data.project_id}/tags/${data.id}`, data.body);
  return response.data;
}

export const deleteTag = async (data) => {
  const response = await axios.delete(`/projects/${data.project_id}/tags/${data.id}`);
  return response.data;
}

export const getAdminStats = async () => {
  const response = await axios.get('/admin');
  return response.data;
}
