import { Outlet } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Loader from '../core/components/Loader';

const useStyles = createUseStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      fontFamily: [theme.font.primary, 'sans-serif'],
    },

    body: {
      backgroundColor: theme.color.white,
    },

    a: {
      textDecoration: 'none'
    },

    h1: {
      marginTop: 0,
      marginBottom: 32,
      color: theme.color.dark,
    },

    h2: {
      marginTop: 0,
      marginBottom: 24,
      color: theme.color.dark,
    },

    h3: {
      marginTop: 0,
      marginBottom: 18,
      color: theme.color.dark,
    },

    '::placeholder': {
      color: theme.color.grey,
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& label': {
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',

        '& span': {
          color: theme.color.dark,
          fontSize: 14,
          lineHeight: '16px',
          fontWeight: '500',
          marginBottom: 6
        },

        '& input': {
          padding: [13, 16],
          border: [1, 'solid', theme.color.grey],
          borderRadius: 6,
          color: theme.color.dark,
          fontSize: 15,
          lineHeight: '18px',
          width: 344,

          '&[disabled]': {
            backgroundColor: theme.color.greyLight,
            color: theme.color.greyDark,
          },

          '&:focus': {
            outline: 'none',
            borderColor: theme.color.brand,
          }
        },

        '& + button': {
          marginTop: 16,
          width: 344
        }
      }
    }
  }
}));

const Root = () => {
  useStyles();

  return (
    <>
      <Loader />
      <Outlet />
    </>
  )
};

export default Root;
