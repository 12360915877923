import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import { useMutation, useQuery } from 'react-query';
import Button from '../../core/components/Button';
import ErrorMessage from '../../core/components/ErrorMessage';
import { getTag, updateTag, deleteTag } from '../../core/api/api';


const useStyles = createUseStyles((theme) => ({
  title: {
    fontFamily: theme.font.brand,
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16
  }
}));

const TagEdit = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tagName, setTagName] = useState();
  const [tagColor, setTagColor] = useState();
  const { project_id, tag_id } = useParams();

  const { data: tag } = useQuery(
    ['tag', project_id, tag_id],
    () => getTag({ project_id, tag_id })
  );

  useEffect(() => {
    if (tag) {
      setTagName(tag.name);
      setTagColor(tag.color);
    }
  }, [tag]);

  const { mutate: mutateUpdate, isLoading, error } = useMutation(updateTag, {
    onSuccess: () => navigate(`/projects/${project_id}/labels`)
  });

  const { mutate: mudateDelete } = useMutation(deleteTag, {
    onSuccess: () => navigate(`/projects/${project_id}/labels`, { replace: true })
  });

  const update = async e => {
    e.preventDefault();
    mutateUpdate({
      id: tag_id,
      project_id,
      body: {
        name: tagName,
        color: tagColor,
      }
    });
  };

  const handleDeletion = async e => {
    e.preventDefault();
    mudateDelete({
      id: tag_id,
      project_id,
    });
  };

  if (isLoading) {
    return 'Please wait...';
  }

  return (
    <div className={classes.page}>
      <form onSubmit={update}>
        <div className={classes.title}>Update label</div>
        <ErrorMessage error={error} customMessagesKey='tag-update' />
        <label>
          <span>Label name</span>
          <input
            defaultValue={tagName}
            placeholder="Name"
            required
            autoFocus
            type="text"
            onChange={e => setTagName(e.target.value)}
          />
        </label>
        <label>
          <span>Label color</span>
          <input
            defaultValue={tagColor}
            placeholder="Color"
            type="text"
            onChange={e => setTagColor(e.target.value)}
          />
        </label>
        <Button disabled={isLoading}>Update</Button>
      </form>
      <div className={classes.buttons}>
        <Button onClick={handleDeletion}>Delete this label</Button>
      </div>
    </div>
  );
};

export default TagEdit;
