import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

import { TOKEN_NAME } from "../consts";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem(TOKEN_NAME));
  const [currentUser, setCurrentUser] = useState(null);

  const saveAuthToken = token => {
    if (token) {
      localStorage.setItem(TOKEN_NAME, token);
    } else {
      localStorage.removeItem(TOKEN_NAME);
    }
    setAuthToken(token);
  };

  const logout = () => {
    saveAuthToken(null)
    setCurrentUser(null)
  }

  const invalidateTokenIfExpired = () => {
    if (authToken) {
      const decodedToken = jwt_decode(authToken);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        logout()
      }
    }
  }

  useEffect(() => invalidateTokenIfExpired(), [authToken]);

  return (
    <AuthContext.Provider value={{
      authToken,
      setAuthToken: saveAuthToken,
      currentUser,
      setCurrentUser,
      logout
    }}>
      { children }
    </AuthContext.Provider>
  )
}

export default AuthContext;
