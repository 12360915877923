import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  error: {
    backgroundColor: '#ffe6e6',
    border: '1px solid #ed5555',
    borderRadius: 3,
    color: '#a80000',
    lineHeight: '1.5',
    marginBottom: 24,
    maxWidth: 700,
    padding: 10,
    textAlign: 'center'
  }
}));

const ErrorMessage = ({ error, customMessagesKey }) => {
  const classes = useStyles()

  if (!error) {
    return null
  }

  const status = error?.response?.status

  let errorMessage = "Whoops! Unfortunately, there was something wrong. Could you please try again in a while?"

  if (customMessagesKey == 'login') {
    if ([401, 423].includes(status)) {
      errorMessage = "Oops! We could not find a user account with this email address and password."
    }
  }

  return (
    <div className={classes.error}>
      { errorMessage }
    </div>
  )
}

export default ErrorMessage
